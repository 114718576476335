import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex } from '../../styles/index'
import Icons from '../../utils/Icons'


interface Props {
  heroVideoVimeo: string
  heroVideoVimeoTitle: string
}

export default function PhilosophyHero(props: Props) {

  const {
    heroVideoVimeo,
    heroVideoVimeoTitle,
  } = props

  const [videoTime, setVideoTime] = useState(0)
  const [isButtonPlay, setIsButtonPlay] = useState(false)
  const videoRef = useRef()

  const handlePlaybutton = ()=>{
    if(!videoRef) return false
    setIsButtonPlay(!isButtonPlay)
    if(videoRef.current.paused){
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }

  function secondsToHms(secondsInput: number) {
    const pad = (n: any) => (n < 10) ? ("0" + n) : n
    let minutes = pad(Math.floor(secondsInput % 3600 / 60) )
    let seconds = pad(Math.floor(secondsInput % 3600 % 60) )
    return `${minutes}:${seconds}`
  }


  return (
    <Wrapper>
        <RelativeWrapper>
        {
          videoRef.current
          &&
          <VideocontrolWrapper>
            <PlayButton 
              onClick={()=>handlePlaybutton()}
              aria-label={isButtonPlay ? 'play video' : 'pause video'}
              type='button'
            >
              <Icons
                name={isButtonPlay ? 'play' : 'pause'}
              />
            </PlayButton>
            <VideoTextWrapper>
              <VideoText>
                {heroVideoVimeoTitle}
              </VideoText>
              <VideoText>
                {`${secondsToHms(videoTime)} / ${secondsToHms(videoRef.current.duration)}`}
              </VideoText>
            </VideoTextWrapper>
          </VideocontrolWrapper>
        }
        <VideoPlayer
          controls={false}
          autoPlay
          disableRemotePlayback
          loop
          playsInline
          muted
          preload="meta"
          ref={videoRef}
          onTimeUpdate={e => setVideoTime(Math.round(videoRef.current.currentTime))}
        >
          
          <source src={`${heroVideoVimeo}`} type="video/mp4" />
        </VideoPlayer>
        </RelativeWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  height: 80vh;
  
  ${desktopBreakpoint}{
    height: 100vh;
  }
`;

const VideoPlayer = styled.video`
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  z-index: ${zIndex.background};
  
  &::-webkit-media-controls {
    display: block;
    -webkit-appearance: block;
  }

  ${desktopBreakpoint}{
    //position: absolute;
    height: 100vh;
    width: 100vw;
    left: auto;
    top: 0;
    right: 0;
  }
`

const VideocontrolWrapper = styled.div`
  position: absolute;
  left: ${mobileVW(5)};
  bottom: ${mobileVW(10)};
  z-index: ${zIndex.surface};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${desktopBreakpoint}{
    left: ${desktopVW(50)};
    bottom: ${desktopVW(50)};

  }
`;

const PlayButton = styled.button`
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${mobileVW(60)};
  height: ${mobileVW(60)};

  ${desktopBreakpoint}{
    width: ${desktopVW(60)};
    height: ${desktopVW(60)};
  }
`;

const VideoText = styled.div`
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  font-size: ${mobileVW(16)};
  color: ${colors.white};

  ${desktopBreakpoint}{
    font-size: ${desktopVW(13)};
  }
`;

const VideoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-left: ${mobileVW(20)};
  ${desktopBreakpoint}{
    padding-left: ${desktopVW(20)}; 
  }
`;

const RelativeWrapper = styled.div`
  position: relative;

`;