import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex } from '../../styles/index';
import RichText from '../shared/RichText';
import { useLocale } from '../../hooks/layout/useLocale';

interface Props {
  introductionHeading: {
    json: string;
  };
  introductionParagraph: {
    introductionParagraph: string;
  };
  introductionMedia: {
    fluid: string;
  };
}

export default function PhilosophyIntroductionSection(props: Props) {
  const { introductionHeading, introductionParagraph, introductionMedia } = props;
  const locale = useLocale();

  return (
    <Wrapper>
      <TopRightImg>
        <StyledImg fluid={introductionMedia[0].fluid} />
      </TopRightImg>
      <MidLeftImg>
        <StyledImg fluid={introductionMedia[1].fluid} />
      </MidLeftImg>
      <BottomLeftImg>
        <StyledImg fluid={introductionMedia[2].fluid} />
      </BottomLeftImg>
      <Text>
        <Heading isNl={locale === 'id'}>
          <RichText json={introductionHeading.json} />
        </Heading>
        <Paragraph>{introductionParagraph.introductionParagraph}</Paragraph>
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-bottom: ${mobileVW(350)};

  ${desktopBreakpoint} {
    min-height: 115vh;
    padding-bottom: 0;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${desktopBreakpoint} {
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: ${mobileVW(300)};
  z-index: ${zIndex.surface};

  h1 {
    text-transform: uppercase;
    font-family: 'messina';
    font-weight: 300;
    line-height: 90%;
    ${({ isNl }) => (isNl ? `font-size: ${mobileVW(30)};` : `font-size: ${mobileVW(80)};`)}

    ${desktopBreakpoint} {
      ${({ isNl }) => (isNl ? `font-size: ${desktopVW(130)};` : `font-size: ${desktopVW(170)};`)}
    }
  }
  ${desktopBreakpoint} {
    padding-top: ${desktopVW(100)};
  }
`;

const Paragraph = styled.div`
  width: 80%;
  font-family: 'messinaMono';
  line-height: 140%;
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  text-align: center;
  padding-top: ${mobileVW(40)};
  z-index: ${zIndex.surface};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14.5)};
    width: 30%;
    padding-top: ${desktopVW(60)};
  }
`;

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
`;

const TopRightImg = styled.div`
  position: absolute;
  top: ${mobileVW(-10)};
  width: ${mobileVW(180)};
  height: ${mobileVW(262)};
  right: ${mobileVW(30)};

  ${desktopBreakpoint} {
    top: 0;
    right: ${desktopVW(120)};
    width: ${desktopVW(241)};
    height: ${desktopVW(350)};
  }
`;

const MidLeftImg = styled.div`
  position: absolute;
  top: ${mobileVW(60)};
  left: 0;
  height: ${mobileVW(202)};
  width: ${mobileVW(145)};

  ${desktopBreakpoint} {
    top: ${desktopVW(150)};
    left: 0;
    width: ${desktopVW(362)};
    height: ${desktopVW(480)};
  }
`;

const BottomLeftImg = styled.div`
  position: absolute;
  bottom: ${mobileVW(30)};
  right: 0;
  width: ${mobileVW(370)};
  height: ${mobileVW(280)};

  ${desktopBreakpoint} {
    bottom: 0;
    right: 0;
    width: ${desktopVW(360)};
    height: ${desktopVW(310)};
  }
`;
